export default {
  errors: {
    "grid-connection-not-present":
      "There is no grid connection application for this project on the platform. This means we cant aid in the resale agreement",
    "grid-connection-not-complete":
      "This project has not been connected to the grid yet",
  },
  card: {
    type: "Type de contrat",
    status: "État",
    restricted: "Non disponible pour le projet",
  },
  types: {
    FEED_IN_TARIFF: "Contrats d’achat",
    VIRTUAL_BATTERY: "Contrats en stockage virtuel",
  },
  unavailable: {
    title: "Service indisponible",
    description:
      "Suivez notre lettre d'information via email ainsi que nos réseaux sociaux pour être informé de la mise en disponibilité.",
  },
  "edf-oa": {
    statuses: {
      NOT_ADDED: "Ajouter service",
      COMPLETE: "Signature du contrat",
      PREPARING: "Préparation",
      NOTIFIED: "Obtention du numéro de contrat",
      ACCOUNT_SETUP: "Ouverture du compte",
      ELECTRICAL_SAFETY_INSPECTION_QUOTE_REQUESTED:
        "Devis demandé de l'organisme inspection",
      ELECTRICAL_SAFETY_INSPECTION_QUOTE_RECEIVED:
        "Devis obtenu de l'organisme inspection",
      ELECTRICAL_SAFETY_INSPECTION_QUOTE_SIGNED:
        "Devis signé de l'organisme inspection",
      ELECTRICAL_SAFETY_INSPECTION_SCHEDULED:
        "Contrôle de conformité ENR prévu",
      ELECTRICAL_SAFETY_INSPECTION_COMPLETE:
        "Rapport ENR d’organisme d’inspection complet",
      SENT: "Demande du contrat prêt pour signature",
      CONFIRMED: "Attente des services d’EDF OA",
    },
    billing: {
      title: "Facturation de la vente de production d’électricité",
      "paragraph-1":
        "Pour obtenir le paiement du montant de vente de surplus de la part d’EDF OA, il faut réaliser une facture depuis l’espace en ligne. D'une manière réglementaire, EDF OA ne peut pas procéder au paiement sans la facture.",
      "paragraph-2":
        "Le montant financier de la vente de production est calculé automatiquement à partir des index (en kWh) fournis lors de la facturation en ligne.",
      "index-reading": {
        title: "Relever les index depuis compteur",
        description:
          "Le montant financier de la vente de production est calculé automatiquement à partir des index (en kWh) fournis lors de la facturation en ligne.",
        linky: {
          heading: "Relever avec compteur Linky",
          "paragraph-1":
            "Il faut relever <strong>deux index</strong> de production et de non-consommation",
          "paragraph-2":
            "Pour relever mes index, j’appuie sur la touche (+) en dessous de l’écran d’affichage. ",
          "list-item-1":
            "Je relève l’index que je vois apparaître « index d’injection » ",
          "list-item-2":
            "Puis je relève l’index de « non-consommation » sous le message « index non conso »",
        },
        mechanical: {
          heading: "Relever avec ancien compteur",
          "paragraph-1":
            "Il faut relever deux index de production et de non-consommation depuis mes compteurs. ",
          "paragraph-2":
            "Si mon compteur est en veille, j’appuie une fois sur la touche 'sélection' afin de voir mon index.",
          "list-item-1":
            "Je relève mon index de production (nombre en kWh) depuis mon compteur Production (injection).",
          "list-item-2":
            "Puis je relève mon index de « non-consommation » (nombre en kWh) depuis mon compteur de « non-consommation », ou consommation des auxiliaires.",
        },
        error:
          "Je ne dois en aucun cas relever les index affichés sur mon onduleur. Cette donnée n’est pas recevable pour EDF Obligation d’Achat.",
      },
      "sending-an-invoice": {
        title: "Envoyer facture à EDF OA",
        description:
          "Vous pouvez facturer uniquement si votre contrat d’achat est signé des 2 parties (producteur et EDF OA. Si votre facturation est annuelle, vous facturez à chaque date anniversaire de la mise en service de votre installation. Si votre facturation est semestrielle, vous devez établir deux factures par an : six mois après la mise en service, puis à la date anniversaire.",
        link: "Vidéo comment faire étape par étape ->",
      },
    },
    support: {
      "customer-support-edf-oa": {
        title: "Service Client EDF OA",
        "text-1": "Adresse email : oa-solaire@edf.fr",
        "text-2": "Téléphone : 09 69 37 57 07",
        "text-3":
          "Pour des raisons de confidentialité, EDF OA ne répond qu'aux personnes dont le nom figure sur le contrat ou mandat.",
      },
      "customer-support-enedis": {
        title: "Problème technique avec votre  compteur ?",
        "text-1":
          "En cas d’incohérence dans vos données de comptage ou en cas de problème sur votre compteur, Il faut contacter le gestionnaire du réseau électrique ENEDIS ou Régie locale d’électricité.  En effet, ce dernier pourrait, si nécessaire, faire une estimation des index.",
        "text-2": "Numéro de téléphone d’Enedis : 09 69 32 18 00",
      },

      cards: {
        "card-1": {
          title: "Vous vendez votre bien équipé avec vente de production ?",
          description:
            "Le cédant ne doit pas résilier son contrat d'achat mais bien signer un avenant de cession de contrat d'achat avec le cessionnaire pour qu'il puisse en bénéficier.",
        },
        "card-2": {
          title: "Vous souhaitez ajouter des panneaux à votre bien ?",
          description:
            "Il convient alors de déposer une nouvelle demande de raccordement auprès d'Enedis en tant qu’augmentation de puissance.",
        },
        "card-3": {
          title: "Votre installation a subi des dommages ?",
          description:
            "Des remplacements de panneaux sont autorisés sous certaines conditions.",
        },
        "card-4": {
          title: "Enedis est intervenu sur votre compteur ?",
          description:
            "Mon compteur a dû être changé, remis à zéro ou j'ai noté une perte de production ?",
        },
        "card-5": {
          title: "Vous souhaitez résilier votre contrat d’achat ?",
          description:
            "Vous devez adresser votre demande par lettre recommandée avec Accusé de Réception à l'Agence Obligation d'Achat.",
        },
        "card-6": {
          title: "Vous vous mariez, pacsez ou vous divorcez ?",
          description:
            "Lorsque mon statut matrimonial change, je peux demander l'ajout ou la suppression de mon conjoint sur le contrat d'achat.",
        },
        "card-7": {
          title: "Le titulaire du contrat d’achat est décédé ?",
          description:
            "Pour transférer le contrat, plusieurs situations peuvent être rencontrées. Il faut fournir le formulaire héritier.",
        },
        "card-8": {
          title: "Votre contrat arrive à échéance ?",
          description:
            "Votre contrat arrive à échéance prochainement et vous souhaitez savoir  quelles sont les démarches à suivre et les possibilités d’avenir pour  votre installation.",
        },

        "find-out-more": "En savoir plus",
      },
    },

    "account-information": {
      heading: "Informations de compte",
      "contract-reference-id": {
        label: "Numéro de contrat EDF OA",
        description:
          "Ce numéro (BTA, BO ou BOA) est obligatoire pour démarrer la démarche.",
      },
      address: "Adresse de l’installation photovoltaïque",
      "contract-owner-name": "Titulaire du contrat",
    },

    "certificate-of-honour": {
      requirements: {
        heading: "Valeurs utilisées pour la complétion du document",
        "contract-reference-id": "Numéro de contrat EDF OA",
        address: "Adresse de l’installation",
        "installation-date": "Déjà posé",
        title: "Titre du signataire",
        "installer-representative-name": "Nom complet de l’installateur",
        "installer-company-name": "Raison sociale de l’installateur",
        "installer-company-address": "Siège social de l’installateur",
        "installer-stamp": "Tampon de l’installateur",
        "panel-brand-name": "Marque des panneaux",
        "panel-model-name": "Référence des panneaux",
        "panel-manufacturer-name": "Fabricant des panneaux",
        "inverter-brand-name": "Marque onduleur (connectique)",
        "inverter-model-name": "Référence onduleur (connectique)",
        "inverter-manufacturer-name": "Fabricant  onduleur (connectique)",
        "disconnect-brand-name": "Marque coffret (boîtier)",
        "disconnect-model-name": "Référence coffret (boîtier)",
        "disconnect-manufacturer-name": "Fabricant  coffret (boîtier)",
        "installer-signature-location": "Emplacement de l’installateur",
        "client-full-name": "Nom du producteur",
        "client-signature-location": "Emplacement du producteur",
      },
    },
  },
  offers: {
    types: {
      EDF_OA_S21_Q1_2025: "S21 T4 2024",
      EDF_OA_S21_Q4_2024: "S21 T3 2024",
      EDF_OA_S21_Q3_2024: "S21 T2 2024",
      EDF_OA_S21_Q2_2024: "S21 T1 2024",
      EDF_OA_S21_Q1_2024: "S21 T4 2023",
      EDF_OA_S21_Q4_2023: "S21 T3 2023",
      EDF_OA_S21_Q3_2023: "S21 T2 2023",
      EDF_OA_S21_Q1_2023: "S21 T4 2022",
      EDF_OA_S24_CORSICA_Q4_2024: "S24 T3 2024 (Corse)",
      EDF_OA_S24_MAYOTTE_Q4_2024: "S24 T3 2024 (Mayotte)",
      EDF_OA_S24_REUNION_ISLAND_Q4_2024: "S24 T3 2024 (La Réunion)",
    },
    "section-headers": {
      EDF_OA_S21_Q1_2025: "4ème trimestre 2024",
      EDF_OA_S21_Q4_2024: "3ème trimestre 2024",
      EDF_OA_S21_Q3_2024: "2ème trimestre 2024",
      EDF_OA_S21_Q2_2024: "1er trimestre 2024",
      EDF_OA_S21_Q1_2024: "4ème trimestre 2023",
      EDF_OA_S21_Q4_2023: "3ème trimestre 2023",
      EDF_OA_S21_Q3_2023: "2ème trimestre 2023",
      EDF_OA_S21_Q1_2023: "4ème trimestre 2022",
      EDF_OA_S24_CORSICA_Q4_2024: "3ème trimestre 2024",
      EDF_OA_S24_MAYOTTE_Q4_2024: "3ème trimestre 2024",
      EDF_OA_S24_REUNION_ISLAND_Q4_2024: "3ème trimestre 2024",
    },
  },
}
