export default {
  CONSUEL: "Visa Consuel (pour habitation)",
  CONSUEL_LARGE_PRODUCER: "Visa Consuel (pour commercial)",
  MAYOR: "Autorisation d'urbanisme (pour habitation)",
  MAYOR_LARGE_PRODUCER: "Autorisation d'urbanisme (pour commercial)",
  GRID_CONNECTION: "Raccordements (< 36kVa)",
  GRID_CONNECTION_LARGE_PRODUCER: "Raccordements (36-250kVa)",
  GRID_CONNECTION_LARGE_PRODUCER_HTA_250_TO_500_KVA:
    "Raccordements (250-500kVa)",
  GRID_CONNECTION_LARGE_PRODUCER_HTA_ABOVE_500_KVA: "Raccordements (>500kVa)",
}
