export default {
  installation: "Installation",
  mayor: {
    title: "Urbanisme",
    "deposit-receipt": "Récépissé de dépôt",
    "missing-information": "Informations manquantes",
    "no-objection": "Non-opposition",
    rejected: "Rejeté",
    DAACT_COMPLETE: "DAACT validée",
  },
  "grid-connection": {
    type: {
      none: "Raccordement",
      "edf-oa": "Raccordement avec EDF OA",
      "urban-solar": "Raccordement avec Urban Solar",
      "my-light": "Raccordement avec My Light",
      jpme: "Raccordement avec JPME",
    },
    states: {
      "edf-oa-my-light": {
        preparing: "Relances préemptives et suivi",
        confirmed: "Complétude du dossier",
        request: "Mise en service commandée",
        complete: "Mise en service réalisée",
        contract: "Contrat EDF OA",
        account: "Obtention identifiant",
        online: "Facturation",
        downpayment: "Paiement de l'acompte",
        finalpayment: "Paiement du solde",
        construction: "Travaux Enedis",
      },
      "urban-solar-jpme": {
        confirmed: "Souscription",
        request: "Mise en service commandée",
        sent: "Complétude du dossier",
        approved: "Mise en service",
      },
    },
  },
  consuel: {
    title: "Consuel",
    states: {
      preparing: "Préparation",
      sent: "Envoyé",
      approved: "Attestation visée",
      ELECTRICAL_SAFETY_INSPECTION_SCHEDULED: "Inspection bureau de contrôle",
    },
  },
}
